'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    appTitle: 'Hegg Energy',
    logoRedirect: 'https://hegg.energy',
    electricityAccentColor: '#BAA8BD',
    electricityProductionAccentColor: '#BAA8BD',
    gasAccentColor: '#BAA8BD',
    mobileDrawerAlign: 'right',
    energyCostAccentColor: '#BAA8BD',
    energyCostBusinessAccentColor: '#3E4836',
    savingsAccentColor: '#BAA8BD',

    /**
     * Mobile menu variants
     * 1. Drawer
     * 2. Page
     */
    mobileMenuVariant: 2,
    phoneNumber: '085 109 1012',
    appDomain: 'Hegg',
    vendorID: '0c9cfcd4-591c-471a-8be4-bdd52b306d48',
    splitBusiness: false,
    myAccount: true,
    smartCharging: false
};