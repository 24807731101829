'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * The items to show in the top menu
   */
  menuItems: ['Contract', 'Je gegevens', 'Besteloverzicht', 'Afronden']
};