"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        background-color: var(--light-grey);
    }
    button {
        font-family: var(--secondary-font-family);
        font-size: 20px !imporant;
        line-height: var(--secondary-font-line-height);
        padding-top: var(--secondary-font-padding-top) !important;
        text-transform: uppercase;
    }

    button.tab {
        color: var(--app-text-color-light);
    }

    button.tab__state--active {
        color: var(--app-text-color);
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
