'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    /**
     * Variants
     * 1. Radio button set
     * 2. Toggle link set
     */
    electricityProductionVariant: 2,

    hideCollective: true,
    hideDualMeters: false,
    hideEstimationTool: false,
    hideExtraInfo: true,

    setElectricityLabelString: '0',
    setElectricityOffString: 'Ik wil geen stroom',
    setElectricityOnString: 'Ik wil ook stroom',
    setElectricityProductionLabelString: '0',
    setElectricityProductionOffString: 'Er is (nog) geen teruglevering',
    setElectricityProductionOnString: 'Ik lever stroom terug',
    setElectricityProductionTitle: 'Teruglevering per jaar',
    setElectricityTitle: 'Stroom per jaar',
    setEstimationToolProductTypeLabel: 'Welke energieproducten wil je graag afnemen?',
    setEstimationToolResidenceTypeLabel: 'Om wat voor een type woning gaat het?',
    setEstimationToolResidentsLabel: 'Hoeveel bewoners heeft deze woning?',
    setEstimationToolTitle: 'Inschatting energieverbruik',
    setEstimationToolButtonString: 'Geen idee? Maak hier een schatting van je energieverbruik.',
    setEstimationToolModalButtonString: 'Maak een schatting',
    setGasLabelString: '0',
    setGasOffString: 'Ik wil geen gas',
    setGasOnString: 'Ik wil ook gas',
    setGasTitle: 'Gas per jaar',

    submitButtonString: 'Berekening afronden',

    /**
     * Variants
     * 1. Full calculation tool with address picker
     * 2. Light calculation tool without address picker
     */
    variant: 2,
    vendorEditURL: 'https://www.hegg.example.weave.nl/energie/',
    vendorEditURLStaging: 'https://md.development.energyzero.nl/'
};