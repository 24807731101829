'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Welkom',
    body: 'Log in en ontdek al ons energie inzicht.',
    emailTitle: 'E-mailadres',
    emailPlaceholder: 'naam@domein.nl',
    passwordTitle: 'Wachtwoord',
    passwordPlaceholder: '•••••••••',
    submit: 'Inloggen',
    forgotPassword: 'Nieuw wachtwoord aanvragen',
    forgotPasswordPre: 'Wachtwoord vergeten?',
    newCustomerPre: 'Nog geen energieklant?',
    newCustomer: 'Bereken je energievoordeel',
    newCustomerRedirectOverwrite: 'https://get.hegg.energy/address',
    newCustomerTargetOverride: 'https://get.hegg.energy/address',
    additionalAddressDialogEnabled: true,
    editURLStaging: 'https://hegg.energy/'
};