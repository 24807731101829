'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  hideSecondaryTitle: true,
  /**
   * Menu position variants
   * 1. Menu in left column
   * 2. Menu above columns
   */
  menuPositionVariant: 2,
  menuSelectedPostAgree: 4,
  menuSelectedPreAgree: 3,
  postAgreeTitle: 'Je bestelling is afgerond',
  /**
   * Menu position variants
   * 1. Sign mandate and pay for meter on page
   * 2. Show button to go to app and sign mandate and pay for meter in app
   */
  postAgreeVariant: 2,
  preAgreeTitle: 'Besteloverzicht',
  termsOfServiceURL: 'https://hegg.energy/',
  vendor: 'Hegg Energy',
  vendorPhoneNumber: '000 000 0000',
  vendorCheckBoxActive: true,
  vendorCheckBoxActiveText: `Ja, ik geef toestemming dat Hegg Energie inzicht heeft in mijn 
    klantgegevens en deze gebruikt om jou te informeren over de afgesproken dienstverlening.`,
  vendorPixel:
  // eslint-disable-next-line max-len
  '//ts.tradetracker.net/?cid=31039&amp;pid=47504&amp;tid=ORDER_ID&amp;tam=ORDER_AMOUNT&amp;data=&amp;qty=1&amp;descrMerchant=&amp;descrAffiliate=&amp;event=sales&amp;currency=EUR&amp;vc=',
  vendorPixelUrl: ['https://api.energyzero.nl/v1']
};