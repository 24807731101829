'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    /**
     * Properties used for app pages
     * 'start' for example is the apps home page
     */
    start: {
        title: 'Welkom bij Hegg',
        body: 'Deze app biedt je gratis inzicht in jouw energieverbruik. Dit is de basis om te kunnen besparen op je energierekening.',
        illustration: 'start.svg',
        primaryBtnTitle: 'Start introductie',
        primaryBtnTarget: 'explanation',
        secondaryBtnTitle: 'Inloggen',
        secondaryBtnTarget: 'lander'
    },
    smartMeter: {
        title: 'Slimme meter',
        body: `Om deze app te kunnen gebruiken is een slimme meter noodzakelijk.
        Ieder huishouden krijgt er een aangeboden voor 2020. Heb jij er al één?`,
        illustration: 'slimmemeters.svg',
        primaryBtnTitle: 'Ja, deze heb ik',
        secondaryBtnTitle: 'Geen slimme meter(s)',
        dialog: {
            title: 'Waarom deze meter?',
            body: `
            De slimme meter is echt
            noodzakelijk, omdat wij deze
            gebruiken om jouw energie data
            op te halen.
            `,
            primaryBtnTitle: 'Oke, ik snap het'
        }
    },
    termsAndConditions: {
        title: 'Voorwaarden & Privacy',
        body: `Jouw gegevens zijn bij ons veilig. In de voorwaarden en het privacybeleid
        staat hoe wij met jouw persoonlijke gegevens omgaan. Dat is wel zo transparant.`,
        illustration: 'slimmemeters.svg',
        primaryBtnTitle: 'Bekijk documenten',
        secondaryBtnTitle: 'Venster sluiten',
        dialog: {
            title: 'Voorwaarden & Privacy',
            body: `
            Zie hieronder de Voorwaarden en het Privacybeleid. Neem de tijd
            om het door te lezen en geef akkoord door hieronder de twee documenten aan te vinken.
            \n
            Met dit akkoord geef je ons toestemming om jouw energiedata
            op halen en deze gegevens overzichtelijk in de app aan
            te bieden.
            `,
            firstDocumentTitle: 'Privacybeleid',
            firstDocumentUrl: 'https://hegg.energy',
            secondDocumentTitle: 'Voorwaarden',
            secondDocumentUrl: 'https://hegg.energy',
            primaryBtnTitle: 'Ja, ik ga akkoord',
            secondaryBtnTitle: 'Venster sluiten'
        }
    },
    idin: {
        title: 'Identificatie met iDIN',
        body: `Als consument kan je jezelf nu identificeren bij andere organisaties met de veilige 
        en vertrouwde inlogmiddelen van jouw eigen bank. Meer over iDIN.`,
        illustration: 'slimmemeters.svg',
        firstDocumentTitle: 'Meer over iDIN.',
        primaryBtnTitle: 'Naar iDIN',
        secondaryBtnTitle: 'Scan slimme meter(s)',
        dialog: {
            title: 'Voorwaarden & Privacy',
            body: `
            Zie hieronder de Voorwaarden en het Privacybeleid. Neem de tijd
            om het door te lezen en geef akkoord door hieronder de twee documenten aan te vinken.
            \n
            Met dit akkoord geef je ons toestemming om jouw energiedata
            op halen en deze gegevens overzichtelijk in de app aan
            te bieden.
            `,
            firstDocumentTitle: 'Privacybeleid',
            firstDocumentUrl: 'https://hegg.energy',
            secondDocumentTitle: 'Voorwaarden',
            secondDocumentUrl: 'https://hegg.energy',
            primaryBtnTitle: 'Ja, ik ga akkoord',
            secondaryBtnTitle: 'Venster sluiten'
        }
    },
    createUser: {
        title: 'Scan de barcode',
        body: `Voor je een scan maakt van je slimme meter(s) willen wij met de onderstaande gegevens direct het benodigde account voor je aanmaken.`,
        enableLabels: false,
        primaryBtnTitle: 'Doorgaan met scannen',
        secondaryBtnTitle: 'Annuleren'
    }
};