"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    flex-rates-component {
        font-family: var(--primary-font-family);

        --primary-color: var(--dark-green);
        --text-color: var(--app-text-color);
        --footer-text-color: var(--app-text-color-light);
        --footer--background-color: var(--lighter-grey) !important;
        --footer-text-active-color: var(--app-text-color) !important;
        --arrow--background-color: var(--dark-green);
    }

    :host([legenda]) #main-legenda {
        border: 2px solid black;
        font-family: var(--secondary-font-family);
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
    barColor: '#baa8bd',
    barHoverColor: '#fff500',
    lineColor: '#909090',
    axisLabelColor: '#909090',
    legendaInformation: ``,
};
