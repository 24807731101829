"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    icons: `<iron-iconset-svg name="ez" size="24">
    <svg>
    <defs>
        <svg id="dot" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-circle fa-w-16"
            data-icon="circle" data-prefix="fas" viewBox="0 0 512 512">
            <path fill="#6b2fad" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/>
        </svg>
        <svg id="home" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <defs>
                <style>
                    .cls-1 {
                        fill: #aaa
                    }
                </style>
            </defs>
            <path class="cls-1" d="M12.5 12.5h9.89v9.89H12.5zM27.61 12.5h9.89v9.89h-9.89zM12.5 27.61h25v9.89h-25z" />
        </svg>
        <svg id="caret-up" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-caret-up fa-w-10"
            data-icon="caret-up" data-prefix="fas" viewBox="0 0 320 512">
            <path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81
                20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/>
        </svg>
        <svg id="caret-down" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-caret-down fa-w-10" 
            data-icon="caret-down" data-prefix="fas" viewBox="0 0 320 512">
            <path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2
                226.1C4.6 213.5 13.5 192 31.3 192z"/>
        </svg>
        <svg id="stroom" aria-hidden="true" data-prefix="fas" data-icon="bolt" role="img" ` + `xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-bolt fa-w-10 fa-3x">
            <path d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 ` + `0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 ` + `8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z" class=""></path>
        </svg>
        <svg id="gas" aria-hidden="true" data-prefix="fas" data-icon="fire" role="img" ` + `xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-fire fa-w-12 fa-3x">
            <path d="M216 23.858c0-23.802-30.653-32.765-44.149-13.038C48 ` + `191.851 224 200 224 288c0 35.629-29.114 64.458-64.85 63.994C123.98 351.538 96 ` + `322.22 96 287.046v-85.51c0-21.703-26.471-32.225-41.432-16.504C27.801 213.158 0 ` + `261.332 0 320c0 105.869 86.131 192 192 192s192-86.131 192-192c0-170.29-168-193.003-168-296.142z" class=""></path>
        </svg>
        <svg id="production" aria-hidden="true" data-prefix="fas" data-icon="sun" role="img" ` + `xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sun fa-w-16 fa-3x">
            <path d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 ` + `96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 ` + `33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 ` + `100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 ` + `21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 ` + `21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 ` + `0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z" class=""></path>
        </svg>
        <svg id="coins" aria-hidden="true" data-prefix="fas" data-icon="coins" role="img" ` + `xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa ` + `fa-coins fa-w-16 fa-3x">
            <path d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 ` + `267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 ` + `64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 ` + `51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 ` + `34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 ` + `56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 ` + `60 57.2z" class=""></path>
        </svg>
        <svg id="savings" aria-hidden="true" data-prefix="fas" data-icon="piggy-bank" 
        class="svg-inline--fa fa-piggy-bank fa-w-18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill="currentColor" d="M560 224h-29.5c-8.8-20-21.6-37.7-37.4-52.5L512 96h-32c-29.4 
            0-55.4 13.5-73 34.3-7.6-1.1-15.1-2.3-23-2.3H256c-77.4 0-141.9 55-156.8 
            128H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 
            6-6v-20c0-3.3-2.7-6-6-6-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 
            288h40c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 
            16-16v-48h128v48c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-80.7c11.8-8.9 22.3-19.4 
            31.3-31.3H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zm-128 64c-8.8 0-16-7.2-16-16s7.2-16 
            16-16 16 7.2 16 16-7.2 16-16 16zM256 96h128c5.4 0 10.7.4 15.9.8 0-.3.1-.5.1-.8 
            0-53-43-96-96-96s-96 43-96 96c0 2.1.5 4.1.6 6.2 15.2-3.9 31-6.2 47.4-6.2z"/>
        </svg>
        <svg id="calendar" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" 
        class="svg-inline--fa fa-calendar-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 448 512">
            <path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 
            48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 
            0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 
            0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 
            0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 
            0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 
            0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 
            0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 
            16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 
            112v48h448v-48c0-26.5-21.5-48-48-48z">
            </path>
        </svg>

        <svg id="hamburger" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 50 50"><defs><style>.cls-1{fill:#3e4836}</style></defs><path 
            class="cls-1" d="M43.2 27.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 
            14.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 40.5a2.5 2.5 0 0 0 
            0-5H6.8a2.5 2.5 0 1 0 0 5z"/>
        </svg>

        <svg id="arrow-left" aria-hidden="true" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" ` + `viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-3x">
            <path fill="#FFF" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 ` + `0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 ` + `24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path>
        </svg>
        <svg id="arrow-right" aria-hidden="true" data-prefix="fas" data-icon="arrow-right" role="img" ` + `xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-3x">
            <path fill="#FFF" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 ` + `33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 ` + `0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" class=""></path>
        </svg>
        <svg id="menu-arrow" aria-hidden="true" data-prefix="fas" data-icon="arrow-right" role="img" ` + `xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-3x">
            <path fill="#FFF" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 ` + `33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 ` + `0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" class=""></path>
        </svg>
        </defs>
    </svg>
</iron-iconset-svg>`
};