'use strict';

var _theme = require('./theme.config');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Bind theme settings and styles to client
window._frontendConnect_ = _theme2.default;

// Dirty Paul hack to remove IBAN from settings when it matches the placeholder IBAN

// Search for this IBAN
const PLACEHOLDER_IBAN = 'NL18RABO0123459876';

// Removes the placeholder IBAN from the page if it exists
const removePlaceholderIBAN = () => {
    try {
        [...window.document.querySelectorAll('ez-app')].flatMap(
        // Get the .propertyWrapper elements through the layers of shadowRoot
        app => [...app.shadowRoot.querySelectorAll('settings-display-container')].flatMap(container => [...container.shadowRoot.querySelectorAll('settings-display')].flatMap(sd => [...sd.shadowRoot.querySelectorAll('.propertyWrapper')]))).filter(
        // Discard all other fields
        pw => pw.querySelector('.attribute').innerText.includes('IBAN-nummer')).filter(
        // Make sure the field contains the placeholder IBAN
        pw => pw.querySelector('.attributeValue').innerText.replace(/\s/g, '').includes(PLACEHOLDER_IBAN)).forEach(
        // Murder
        pw => pw.remove());
    } catch (_) {
        // Let's not crash the page
    }
};

// IBAN remover goes brrrrrrrr (10x per second)
setInterval(removePlaceholderIBAN, 100);