"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        text-transform: uppercase;
    }

    span {
        font-family: var(--secondary-font-family) !important;
        padding-top: var(--secondary-font-padding-top);
        font-size: 20px !important;
        line-height: --var(--secondary-font-line-height);
    }

    button[theme='primary'] {
        border-radius: 100px !important;
        height: 56px;
    }

    button[verticalspacing='none'] {
        height: 40px;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
