'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Account',
    termsOfServiceURL: 'https://hegg.energy',
    splitBusiness: true,
    splitBusinessInformation: 'Splits je energierekening in zakelijk en privé. ' + 'Na het aanzetten zie je binnen 24 uur Energiesplitter ' + 'in het hoofdmenu verschijnen. Vul hier je rooster in en ' + 'volg vanaf dat moment dagelijks de opsplitsing van je ' + 'energiekosten. Uiteindelijk ontvang je een zakelijk ' + 'kostenoverzicht op je jaarrekening. Voor energiekosten ' + 'die zakelijk worden geregistreerd betaal je dezelfde ' + 'energietarieven. Probeer het de eerste 6 maanden gratis, ' + 'daarna betaal je automatisch slechts 2,- euro per maand. ' + 'Op de pagina Voorwaarden in de PDF Energiesplitter ' + 'Gebruikersovereenkomst vind je meer productinformatie.',
    changeSettingsButtonString: 'Wijzigen'
};