"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        font-family: var(--primary-font-family);
        color: var(--dark-green);
    }

    #monthly-pricing__header h1 {
        font-family: var(--secondary-font-family) !important;
        line-height: var(--secondary-font-line-height);
        padding-top: var(--secondary-font-padding-top);
        text-transform: uppercase;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
